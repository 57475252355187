/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {RoomModel} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

const RoomListContainer = Styled.View`
  padding-horizontal: 16px;
`;

const RoomListItemContainer = Styled.Touchable`
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
  flex-direction: row;
`;

const ThumbnailContainer = Styled.View`
  height: 90px;
  width: 108px;
  background-color: ${colors.gray.border};
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
`;

const RoomInformationSection = Styled.View`
`;

const RoomName = Styled.H6`
  color: ${colors.black};
  ${fontWeight(700)}
`;

const DetailBadgesContainer = Styled.View`
  flex-direction: row;
`;

const DetailBadge = Styled.View`
  padding-horizontal: 8px;
  padding-vertical: 2px;
  margin-right: 8px;
  border-radius: 20px;
  background-color: ${colors.blue.accent};
`;

const DetailBadgeText = Styled.H8`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const Image = Styled.Image`
  height: inherit;
  width: inherit;
`;

const RoomListItem = ({room}: any) => {
  const {navigator, params} = useNavigation();
  return (
    <RoomListItemContainer
      onPress={() =>
        navigator.push('ShowRoomInventoryPage', {
          uuid: params.uuid,
          roomUuid: room.uuid,
          action: 'update',
        })
      }
    >
      {_.get(room, 'latestVideoAttachment.file.thumbnailFile.downloadUrl') ? (
        <ThumbnailContainer>
          <Image source={{uri: room.latestVideoAttachment.file.thumbnailFile.downloadUrl}} />
        </ThumbnailContainer>
      ) : (
        <ThumbnailContainer>
          <Icon color={colors.gray.tertiary} size={20} source={Icon.VideoSlash} />
        </ThumbnailContainer>
      )}
      <Space width={16} />
      <RoomInformationSection>
        <Space height={16} />
        {/* Custom rooms do not have a room type so we use the room name */}
        <RoomName>{room.roomType?.name || room.name}</RoomName>
        <Space height={8} />
        <DetailBadgesContainer>
          {room.filteredAttachments.length > 0 && (
            <DetailBadge>
              <DetailBadgeText>closet</DetailBadgeText>
            </DetailBadge>
          )}
          {!!room.customerNotes && (
            <DetailBadge>
              <DetailBadgeText>notes</DetailBadgeText>
            </DetailBadge>
          )}
        </DetailBadgesContainer>
      </RoomInformationSection>
    </RoomListItemContainer>
  );
};

const RoomList = ({rooms}: {rooms: RoomModel[]}) => {
  return (
    <RoomListContainer>
      <FlatList
        listKey={'room-list'}
        data={rooms}
        renderItem={({item: room}) => <RoomListItem room={room} />}
        ItemSeparatorComponent={() => <Space height={16} />}
        // @ts-expect-error this is a weird thing to add together
        keyExtractor={(location, index) => String(location + index)}
      />
    </RoomListContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RoomList.fragment = gql`
  fragment RoomList on Room {
    id
    uuid
    customerNotes
    filteredAttachments(attachmentCategoryKinds: ["ROOM_PHOTO"]) {
      id
    }
    roomType {
      id
      name
    }
    latestVideoAttachment {
      id
      file {
        id
        thumbnailFile {
          id
          downloadUrl
        }
      }
    }
  }
`;

export default RoomList;
